import { defineComponent } from 'vue';
import FloatTab from '@/components/floatTab.vue';
import { MOBILE_FUNC } from '@/uitl/currencyFunc.js';
export default defineComponent({
  components: {
    FloatTab
  },
  setup() {
    let isMobile = MOBILE_FUNC();
    const toPage = function (url) {
      window.open(url);
    };
    return {
      toPage,
      isMobile
    };
  }
});