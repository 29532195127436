import { ref, onMounted, defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    headerType: {
      type: String,
      default: ''
    }
  },
  setup() {
    const store = useStore();
    watch(() => store.state.aboutActive, newValue => {
      let num = window.location.href;
      if (num.indexOf('aboutUs.html') >= 0) {
        if (newValue && newValue === 'enterprise-com') {
          activeNum.value = 4.1;
        }
        if (newValue && newValue === 'join-us') {
          activeNum.value = 4.2;
        }
        if (newValue && newValue === 'contact-us') {
          activeNum.value = 4.3;
        }
      }
    }, {
      immediate: true
    });
    let scrollTop = ref(0),
      activeNum = ref(0),
      isShowMenu = ref(false);
    const setHeader = () => {
      let num = window.location.href;
      if (num.indexOf('index.html') >= 0) {
        activeNum.value = 0;
      }
      if (num.indexOf('codeProduct.html') >= 0) {
        activeNum.value = 1.1;
      }
      if (num.indexOf('sastProduct.html') >= 0) {
        activeNum.value = 1.2;
      }
      if (num.indexOf('consultingService.html') >= 0) {
        activeNum.value = 1.3;
      }
      if (num.indexOf('safetyKnowledgeProduct.html') >= 0) {
        activeNum.value = 1.4;
      }
      if (num.indexOf('codeSafety.html') >= 0) {
        activeNum.value = 1.5;
      }
      if (num.indexOf('aigcProduct.html') >= 0) {
        activeNum.value = 1.6;
      }
      if (num.indexOf('modelTraining.html') >= 0) {
        activeNum.value = 1.7;
      }
      if (num.indexOf('corporateBlog.html') >= 0) {
        activeNum.value = 2.1;
      }
      if (num.indexOf('commonProblem.html') >= 0) {
        activeNum.value = 2.2;
      }
      if (num.indexOf('blogInfo') >= 0) {
        activeNum.value = 2.1;
      }
      if (num.indexOf('cooperatePage.html') >= 0) {
        activeNum.value = 3;
      }
      if (num.indexOf('aboutUs.html?type=1') >= 0) {
        activeNum.value = 4.1;
      }
      if (num.indexOf('aboutUs.html?type=2') >= 0) {
        activeNum.value = 4.2;
      }
      if (num.indexOf('aboutUs.html?type=3') >= 0) {
        activeNum.value = 4.3;
      }
      if (num.indexOf('fuzzTest.html') >= 0) {
        activeNum.value = 5.1;
      }
      if (num.indexOf('safetyTest.html') >= 0) {
        activeNum.value = 5.2;
      }
      if (num.indexOf('confrontationTest.html') >= 0) {
        activeNum.value = 5.3;
      }
      if (num.indexOf('nourishTest.html') >= 0) {
        activeNum.value = 5.4;
      }
      if (num.indexOf('competitionTest.html') >= 0) {
        activeNum.value = 5.5;
      }
    };
    const showMenu = () => {
      isShowMenu.value = !isShowMenu.value;
    };
    onMounted(() => {
      setHeader();
      // 监听鼠标滚动，设置头部背景颜色
      window.onmousewheel = document.onmousewheel = () => {
        scrollTop.value = document.documentElement.scrollTop || document.body.scrollTop;
      };
    });
    return {
      isShowMenu,
      showMenu,
      activeNum,
      scrollTop
    };
  }
});