import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6627091c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  key: 0,
  class: "tips"
};
const _hoisted_3 = {
  class: "tips-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "top-box",
    style: _normalizeStyle({
      'background': 'url(' + _ctx.bgUrl + ') center center / cover no-repeat'
    })
  }, [_createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1), _ctx.tips ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.tips), 1)])) : _createCommentVNode("", true)], 4);
}