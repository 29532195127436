import { defineComponent } from 'vue';
import ButtomCom from '@/components/buttomCom.vue';
import { JUMP_PAGE } from '@/uitl/currencyFunc';
export default defineComponent({
  components: {
    ButtomCom
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    buttomName: {
      type: String,
      default: ''
    },
    ngUrl: {
      type: String,
      default: () => {}
    }
  },
  setup() {
    const jumpPage = url => {
      // 获取当前页面地址
      url = `${url}?trackCode=${window.location.href.split('//')[1]}`;
      JUMP_PAGE(url);
    };
    return {
      jumpPage
    };
  }
});