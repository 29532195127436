import axios from 'axios'
// import vue from '@/main'
import { message } from 'ant-design-vue'
// import { getToken } from '@/utils/auth.js'
// import { changeMsg } from './changeMsg.js'

const baseURL = ''
// 创建一个 axios 实例
const service = axios.create({
	baseURL: baseURL, // 开发环境
	timeout: 20000, // 请求超时时间毫秒
	headers: {
		// 设置后端需要的传参类型
		'Content-Type': 'application/json; charset=utf-8',
		'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*' //发送后端能够接受的前端域名
        // 'Access-Control-Allow-Origin': 'http://192.168.31.147'
	}
})
service.defaults.withCredentials = true

// 添加请求拦截器
service.interceptors.request.use(
	function (config) {
		// 在发送请求之前做些什么
		// if(getToken()){
		// 	config['headers']['userToken'] = getToken()
		// }
		return config
	},
	function (error) {
		console.log(error)
		// 对请求错误做些什么
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	function (response) {
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response.data
		// 这个状态码是和后端约定的
		let code = dataAxios.code
		if (code === 0) {
			return dataAxios
		} else {
			message.error({
				content: dataAxios.msg ? dataAxios.msg : '系统错误！',
				duration: 5 
			})
		}
	},
	function (error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		const { response } = error
		const msg = response.data.message
		if(response.status == 401){ //token失效
			message.error({
				content: msg ? msg : 'token失效，请重新登录！',
				duration: 5 
			})
			setTimeout(()=>{
				const str = window.location.href.split('/#/')[0] + '/#/login'
				window.location.href = str
			}, 500)
			return
		}else{
			message.error({
				content: msg ? msg : '系统错误！',
				duration: 5 
			})
		}
		return Promise.reject(response)
	}
)

export default service

