import { defineComponent } from 'vue';
import ButtomCom from './buttomCom.vue';
import { MOBILE_FUNC, JUMP_PAGE } from '@/uitl/currencyFunc';
export default defineComponent({
  props: {
    type: {}
  },
  components: {
    ButtomCom
  },
  setup() {
    const isMobile = MOBILE_FUNC();
    // const setHeight = () => {
    //     41.67% + 

    //     let DOM = document.getElementsByClassName('success-box')[0]
    //     let H = DOM.getBoundingClientRect().top
    //     console.log(H, DOM)
    //     // 获取dom
    //     // const companyDom = document.querySelector('#successIcon')
    //     // console.log(companyDom.offsetTop)
    //     // 设置位置
    //     window.scroll(0, 1300, 0)
    // }
    return {
      toPage: JUMP_PAGE,
      // setHeight,
      isMobile
    };
  }
});