import http from './http'
const PREFIX = '/api'
const SAVE_BOOKDEMO = `${PREFIX}/bookDemo/save` //保存预约演示信息
const SAVE_COOPERATE = `${PREFIX}/cooperate/save` //保存预约演示信息
const SAVE_DOWNLOAD = `${PREFIX}/download/save` //保存预约演示信息

/**
 * 保存预约演示信息
 */
const saveBookDemo = (data) => {
    // const form = new FormData()
    // for(let key in data){
    //     form.append(key, data[key])
    // }
    return http.request({
        url: SAVE_BOOKDEMO,
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
}
/**
 * 保存诚信合作信息
 */
const saveCooperate = (data) => {
    // const form = new FormData()
    // for(let key in data){
    //     form.append(key, data[key])
    // }
    return http.request({
        url: SAVE_COOPERATE,
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
}

const saveDownloadInfo = (data) => {
    return http.request({
        url: SAVE_DOWNLOAD,
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'application/json; charset=utf-8',
        },
    })
}
export default {
    saveBookDemo: saveBookDemo,
    saveCooperate: saveCooperate,
    saveDownloadInfo: saveDownloadInfo,
}
