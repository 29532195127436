import { defineComponent } from "vue";
import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/footerCom.vue';
import ArticleCom from '@/module/corporateBlog/components/articleCom.vue';
import { Row, Col } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { blogList } from '@/module/corporateBlog/components/blogs.js';
export default defineComponent({
  components: {
    ARow: Row,
    ACol: Col,
    FooterCom,
    HeaderCom,
    ArticleCom
  },
  setup() {
    let value = window.location.href,
      blogInfo,
      activeNum;
    // 根据当前选中的博客文章id，遍历找到该文章详情内容
    for (let i = 0; i < blogList.length; i++) {
      if (value.indexOf(`blogInfo${blogList[i].blogUUID}.html`) >= 0) {
        blogInfo = blogList[i];
        // 当前文章所属下标
        activeNum = i;
      }
    }
    // 针对选中的博客文章，改造文章内容
    blogInfo.blogContent = blogInfo.blogContent.replace(/<img/g, '<img style="display:block;margin: 0 auto;"');
    blogInfo.blogContent = blogInfo.blogContent.replace(/<p>/g, '<p style="margin: 24px 0;">');
    // document.getElementsByTagName("title")[0].innerText = blogInfo.blogTitle
    // 设置最近的3篇文章
    let articleList = blogList.slice(activeNum + 1, activeNum + 4);
    if (articleList.length <= 0) {
      articleList = blogList.slice(0, 3);
    }
    return {
      articleList,
      blogInfo
    };
  }
});