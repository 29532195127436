import { defineComponent } from 'vue';
import { JUMP_PAGE } from '@/uitl/currencyFunc.js';
export default defineComponent({
  props: {
    articleItem: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    // 查看博客详情
    const lookInfo = obj => {
      let url = `/blogInfo${obj.blogUUID}.html`;
      window.sessionStorage.setItem('blogTitle', obj.blogTitle);
      url = encodeURI(url);
      JUMP_PAGE(url);
    };
    return {
      lookInfo
    };
  }
});