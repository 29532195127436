import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  props: {
    problemTitle: {
      type: String,
      default: ''
    },
    problemContent: {
      type: String,
      default: ''
    },
    refName: {
      type: String
    }
  },
  emits: ['activeFunc'],
  setup(props) {
    const store = useStore();
    let showType = ref(false);
    const showFunc = () => {
      showType.value = !showType.value;
      if (showType.value && !store.state.activeProblem) {
        store.commit('setActiveProblem', props.refName);
      }
      if (showType.value && store.state.activeProblem && store.state.activeProblem != props.refName) {
        store.commit('setActiveProblem', props.refName);
      }
      if (!showType.value && store.state.activeProblem != props.refName) {
        showType.value = true;
        store.commit('setActiveProblem', props.refName);
      }
      if (!showType.value && store.state.activeProblem == props.refName) {
        store.commit('setActiveProblem', null);
      }
    };
    return {
      store,
      showType,
      showFunc
    };
  }
};