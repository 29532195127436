import { MOBILE_FUNC } from '@/uitl/currencyFunc.js';
import { defineComponent, reactive, ref } from 'vue';
import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/footerCom.vue';
import ButtomCom from '@/components/buttomCom.vue';
import SuccessCom from '@/components/successCom.vue';
import { Form, FormItem, Input, Row, Col } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import HTTP from '@/assets/axios/index.js';
export default defineComponent({
  components: {
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: FormItem,
    AInput: Input,
    HeaderCom,
    FooterCom,
    ButtomCom,
    SuccessCom
  },
  setup() {
    const isMobile = MOBILE_FUNC();
    let fromDOM = ref(null);
    let formState = reactive({
      username: '',
      inquiryProduct: '无垠代码模糊测试',
      contactMail: '',
      contactPhone: ''
    });
    let isSuccess = ref(false);
    const saveData = () => {
      let trackCode;
      // 移动端上，当前模块以子组件的形式展示，非独立页面，所以获取来源页面地址可直接获取当前的浏览器地址
      if (isMobile) {
        trackCode = window.location.href.split('//')[1];
      } else {
        // PC端
        let value = window.location.search;
        if (value) {
          trackCode = value.split('trackCode=')[1];
        }
      }
      fromDOM.value.validate().then(() => {
        HTTP.saveBookDemo({
          fullName: formState.username,
          telephone: formState.contactPhone,
          mailbox: formState.contactMail,
          inquiryProduct: formState.inquiryProduct,
          trackCode: trackCode,
          type: 'inquiry'
        }).then(res => {
          if (res.code === 0) {
            fromDOM.value.resetFields();
            isSuccess.value = true;
          }
        });
      }).catch(err => {
        console.log(err);
      });
    };
    const selectChange1 = e => {
      formState.contactMail = e.target.value;
    };
    const selectChange = e => {
      formState.inquiryProduct = e.target.value;
    };
    return {
      selectChange,
      selectChange1,
      isSuccess,
      fromDOM,
      saveData,
      isMobile,
      formState
    };
  }
});