import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7dc0dee4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "problem-line"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
    class: _normalizeClass($setup.showType && $setup.store.state.activeProblem == $props.refName ? 'problem active' : 'problem')
  }, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.problemTitle), 1), _createElementVNode("span", {
    class: "expand",
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.showFunc && $setup.showFunc(...args))
  }, "+"), _createElementVNode("span", {
    class: "put",
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.showFunc && $setup.showFunc(...args))
  }, "-")], 2), _createElementVNode("div", {
    class: _normalizeClass($setup.showType && $setup.store.state.activeProblem == $props.refName ? 'collapse active' : 'collapse')
  }, [_createElementVNode("div", {
    class: "problem-content card-body",
    innerHTML: $props.problemContent
  }, null, 8, _hoisted_3)], 2)]);
}