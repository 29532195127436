import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    title: {
      tyle: String,
      default: ''
    },
    tips: {
      tyle: String,
      default: ''
    },
    bgUrl: {
      tyle: Object,
      default: () => {}
    }
  }
});