// 判断是否是移动端
export const MOBILE_FUNC = () => {
    let data = false //, width = document.body.offsetWidth
    if (
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        data = true
    }

    return data
}

//页面跳转
export const JUMP_PAGE = (url) => {
    window.location.href = url
}

// 定义移动端-全局rem换算
export const setPageRem_Mobile = () => {
    const WIDTH = 375 //如果是尺寸的设计稿在这里修改
    const setView = () => {
        //设置html标签的fontSize
        document.documentElement.style.fontSize =
            (100 * screen.width) / WIDTH + 'px'
    }
    window.onresize = setView
    setView()
}
// 定义PC端-全局rem换算
export const setPageRem_PC = () => {
    const WIDTH = 1920 //如果是尺寸的设计稿在这里修改
    const setView = () => {
        //设置html标签的fontSize
        document.documentElement.style.fontSize =
            (100 * screen.width) / WIDTH + 'px'
    }
    window.onresize = setView
    setView()
}
export const setPageRem = () => {
    if (
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        setPageRem_Mobile()
    } else {
        setPageRem_PC()
    }
}

// 监视页面滚动，设置页面中模块的动态效果
export const wachtPageAni = (LIST) => {
    // 浏览器高度
    let WH = window.innerHeight
    // 网页滚动条监控
    window.onscroll = function () {
        for (let i = 0; i < LIST.length; i++) {
            if (LIST[i].value) {
                // 当前模块离浏览器顶部距离 <= (WH - 160)
                let toTop = LIST[i].value.$el.getBoundingClientRect().top,
                    spacing = MOBILE_FUNC() ? 64 : 100
                if (toTop <= WH - spacing) {
                    LIST[i].value.pageActive = true
                }
            }
        }
    }
}
