import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-33cbd2a4"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
import { ref, onMounted } from 'vue';
export default {
  __name: 'buttomCom',
  props: {
    btnName: {
      type: String,
      default: '确定'
    },
    // 是否根据父组件大小填充
    filling: {
      type: Boolean,
      default: true
    },
    isSubmit: {
      type: Boolean,
      default: true
    },
    isArrow: {
      type: Boolean,
      default: false
    },
    isMore: {
      type: Boolean,
      default: false
    },
    effect: {
      type: String,
      default: 'plain'
    }
  },
  setup(__props) {
    // import { defineComponent } from 'vue'
    const props = __props;
    let style = ref();
    const setTheme = () => {
      if (props.effect === 'dark') {
        style.value = {
          color: '#FFFFFF',
          background: '#101421',
          borderRadius: '4px',
          border: 'none'
        };
      } else if (props.effect === 'light') {
        style.value = {
          color: '#298CFF',
          background: '#FFFFFF',
          borderRadius: '4px',
          border: '1px solid #298CFF'
        };
      }
    };
    onMounted(() => {
      setTheme();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        id: "button",
        style: _normalizeStyle(_unref(style)),
        class: _normalizeClass(__props.isSubmit ? 'fuzz-button fuzz-submit flicker' : 'fuzz-button flicker'),
        type: "primary"
      }, [_createElementVNode("span", null, [_createTextVNode(_toDisplayString(__props.btnName) + " ", 1), __props.isArrow && !__props.isMore ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: "img-iocn",
        src: require('@/assets/img/serve/arrow.svg'),
        alt: ""
      }, null, 8, _hoisted_1)) : _createCommentVNode("", true), !__props.isArrow && __props.isMore ? (_openBlock(), _createElementBlock("img", {
        key: 1,
        class: "img-iocn",
        alt: "",
        src: require('@/assets/img/index/checkNext.svg')
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true)])], 6);
    };
  }
};