import { createStore } from 'vuex'

export default createStore({
	state:  sessionStorage.getItem('state') ? JSON.parse((sessionStorage).getItem('state')) : {
			aboutActive: null,  //用户信息
			activeProblem: null, // 当前激活的问题
		}
	,
	mutations: {
		setAboutActive(state, data){
			state.aboutActive = data
		},
		setActiveProblem(state, data){
			state.activeProblem = data
		}
	},
	actions: {

	},
	modules: {

	}
})
