import { defineComponent, ref } from 'vue';
import BoookDemoPage from '@/module/bookDemoPage/bookDemoPage.vue';
import { MOBILE_FUNC } from '@/uitl/currencyFunc.js';
export default defineComponent({
  components: {
    BoookDemoPage
  },
  setup() {
    const isMobile = MOBILE_FUNC();
    let showRQcode = ref(false);
    let showDemoPage = ref(false);
    const clickCode = type => {
      if (type == 'demoPage') {
        showDemoPage.value = !showDemoPage.value;
      } else {
        showRQcode.value = !showRQcode.value;
      }
    };
    const jumpPage = url => {
      // 获取当前页面地址
      url = `${url}?trackCode=${window.location.href.split('//')[1]}`;
      window.open(url);
    };
    return {
      jumpPage,
      clickCode,
      showDemoPage,
      showRQcode,
      isMobile
    };
  }
});